<template>
  <sub-page-top
    title="News一覧"
    height="250px"
  >
    <p>
      SellerPortの新着情報、お知らせをご確認ください。
    </p>
  </sub-page-top>
  <!-- <Breadcrumb menu="News" /> -->
  <div class="box">
    <dl v-for="news in newsList" 
      @click="toDetail(news)"
      :key="news.id">
      <dt>{{news.date}}</dt>
      <dd>{{news.title}}</dd>
    </dl>
  </div>
  <div class="pagination">
    <span class="arrow-left"></span>
    <span class="selected">1</span>
    <span class="arrow-right"></span>      
  </div>  
</template>

<script>
// import Breadcrumb from '../components/atoms/Breadcrumb.vue'
import SubPageTop from '@/components/molecules/SubPageTop.vue'

export default {
  name: 'News',
  components: {
    SubPageTop,
  },
  data() {
    return {
      newsList: []
    }
  },
  created() {
    this.$api.get('https://cozhowm9fh.execute-api.ap-northeast-1.amazonaws.com/default/buybuy-news').then(res => {
      this.newsList = [...res.data.reverse()].map(n => {
        return {
          id: n.name.split('_')[0],
          date: n.name.split('_')[1],
          title: n.name.split('_')[2]
        }
      })
    })
  },
  methods: {
    toDetail(news) {
      location.href = `/news/${news.id}`
    }
  }
}
</script>

<style lang="scss">
.sub-page-top {
  background: url(../assets/news-top.png);
}
.box {
  margin: 100px auto 20px auto;
  width: 80%;
  max-width: 800px;
  background-color: #F6F6F6;
  border-radius: 5px;
  dl {
    display: block;
    dt {
      font-weight: bold;
      color: #F2B504;
    }
    dd {
      cursor: pointer;
      &:hover {
        color: #F2B504;
      }
    }    
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  span {
    margin: 0 10px;
  }
  .arrow-left {
    border: solid #F2B504;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .arrow-right {
    border: solid #F2B504;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .selected {
    background: #ebb512;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    width: 30px;
    height: 30px;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;  
  }
}

@media (min-width: 751px) {
  .box {
    padding: 50px 0;
    dl {
      display: block;
      font-weight: bold;
      dt {
        display: inline-flex;
        width: 25%;
        justify-content: center;
        color: #F2B504;
      }
      dd {
        display: inline-flex;
        width: 70%;
        margin-inline-start: 0;
        cursor: pointer;
        &:hover {
          color: #F2B504;
        }
      }    
    }
  }
}

@media screen and (max-width: 750px) {
  .sub-page-top {
    background: url(../assets/news-top-sp.png);
  }  
  .box {
    width: 88%;
    padding: 20px 10px;
    dl {
      dt {
        margin-bottom: 5px;
      }
      dd {
        margin-inline-start: 0;
      }
    }
  }
}
</style>