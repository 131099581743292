<template>
  <div class="sub-page-top">
    <div class="sub-page-content">
      <h1>{{title}}</h1>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubPageTop',
  props: {
    title: String,
    height: String,
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-page-top {
  background-color: #FF6E1F;
  background-size: cover;
  background-position: center;
  height: v-bind(height);
  .sub-page-content {
    background: rgba(51,51,51,0.78);
    height: 100%;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h1 {
      border-bottom: 1px solid white;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}

@media (min-width: 751px) {
  .sub-page-content {
    padding-left: 8%;
    h1 {
      width: 30%;      
    }
  }  
}

@media screen and (max-width: 750px) {
  .sub-page-content {
    padding: 0 8%;
    .flow-explain {
      flex-direction: column;
      .step {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
